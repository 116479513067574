import VsPublisher from './vs-publisher.vue';
import VsSubscriber from './vs-subscriber.vue';

export interface VsPublisherInstance extends Vue {
    publish: (stramName?: string) => Promise<void>;
    finishVideoStreaming: () => void;
    switchVideoToScreen: () => Promise<void>;
    switchVideoToCamera: () => Promise<void>;
}

export interface VsSubscriberInstance extends Vue {
    changeVideoState: (newState: boolean) => void;
    subscribe: () => Promise<void>;
}
export interface Participante {
    streamId: string;
    idParticipante: string;
    nomeParticipante: string;
    admin: boolean;
    mic: boolean;
    cam: boolean;
    telaCompartilhada: boolean;
    avatarUrl: string;
}

export { VsPublisher, VsSubscriber };